import 'lazysizes'
import './bootstrap'
import whenDomReady from 'when-dom-ready'
import nav from './modules/nav'

whenDomReady(() => {
  const route = dq('.main').getAttribute('data-route')
  nav()

  if ( process.env.SERVICE_WORKER == 'true' ) {
    import('./modules/service-worker').then(fn => fn.default())
  }
  // $(window).scroll(function() {
  //   var scroll = $(window).scrollTop();
  //   let mobileNav = $('.main-nav-header')
  //   if (scroll >= 37) {
  //     mobileNav.removeClass('logged-in')
  //   } else {
  //     mobileNav.addClass('logged-in')
  //   }
  // })

  switch (route) {
    case '/page/front':
      import('./modules/swiper').then(fn => fn.default())
      import('./plugins/image-slider').then(fn => fn.default('.header-slider'))
      import('./plugins/resize-container').then(fn => {
        fn.default('.header-slider', '.slide')
        fn.default('.contents-wrap', '.bg-img', true)
      })
      break
    case '/page/page-gallery':
      import('./modules/vue-gallery')
      import('./plugins/resize-container').then(fn => {
        fn.default('.contents-wrap', '.bg-img', true)
      })
      break
    case '/single/single-escort':
      import('./modules/photoswipe').then(fn => fn.default('.escort-gallery-thumbs'))
      import('./modules/vue-rates-table')
      break
  }
})

if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept([
    './plugins/image-fit.ts',
    './plugins/resize-container.ts',
  ], () => {
    // require the updated modules
    // have to add .default here due to babel 6 module output
    const newImageSlider = require('./plugins/image-slider').default
    const newResizeCont = require('./plugins/resize-container.ts').default
    newImageSlider('.header-slider')
    newResizeCont('.header-slider', '.slide');
    newResizeCont('.contents-wrap', '.bg-img', true);
  })
}
