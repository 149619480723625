import searchModal from './search-modal'
export default () => {
  new Vue({
    el: '.main-nav-wrap',
    data: {
      shown: false,
      search: false,
    },
    methods: {
      toggle() {
        this.shown = !this.shown
      },
      toggleSearch() {
        searchModal.$children[0].hidden = false
      },
    },
  })
}
