import Vue from 'vue'
import pluralize from 'pluralize'

if (process.env.APP_DEBUG == 'true') {
  Vue.config.devtools = true
}

Vue.filter('capitalize', string => string.charAt(0).toUpperCase() + string.slice(1))
Vue.filter('pluralize', string => pluralize(string))



global.Vue = Vue
global.dq = function(selector) {
  if (selector[0] === '.') {
    return document.querySelector(selector)
  }
  return document.getElementById(selector)
}
