<template>
  <transition name="modal">
    <div
      v-if="!hidden"
      class="modal-mask"
    >
      <div class="modal-container">
        <div class="modal-body">
          <form
            role="search"
            method="get"
            class="main-nav-search"
            action="/"
            _lpchecked="1"
          >
            <input
              id="s"
              type="text"
              class="search-input"
              value=""
              placeholder="Search..."
              name="s"
            >
            <input
              type="submit"
              class="search-btn"
              value="Search"
            >
          </form>
        </div>
        <div
          v-if="controls"
          class="modal-controls"
        >
          <button
            type="button"
            class="button"
            @click="$emit('yes')"
          >
            Yes
          </button>
          <button
            type="button"
            class="button"
            @click="$emit('close')"
          >
            No
          </button>
        </div>
        <button
          class="close-button"
          aria-label="Close modal"
          type="button"
          @click="hidden=true"
        >
          <span>×</span>
        </button>
      </div>
    </div>
  </transition>
</template>
<script>
  export default {
    props: ['controls'],
    data: function() {
      return {
        hidden: true,
      }
    },
    methods: {
      test() {
        console.log('test')
      },
    },
  }
</script>
